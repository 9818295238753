import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Fade from 'react-reveal/Fade'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Footer from '../components/Footer'

const footerLinks = ['the-kawatea', 'pot-fishing', 'past']

const GlossaryPage = ({ location, data }) => {
  const page = data.prismicTeReo.data
  return (
    <Layout>
      <SEO
        title={page.meta_title}
        description={page.meta_description}
        location={location}
      />

      <div className="gutters pt-hh">
        <div className="relative">
          <div className="w-full lg:w-20/24 lg:mx-auto relative z-10 pb-20">
            <Fade delay={225} duration={2250}>
              <div className="uppercase font-medium text-center py-14">
                <h1 className="text-3xl md:text-6xl lg:text-9xl leading-tight tracking-wide">
                  {page.heading}
                </h1>
                <h2 className="tracking-widest text-xs lg:text-base">
                  {page.subheading}
                </h2>
              </div>
            </Fade>
            <Fade delay={250} duration={2250}>
              <ul>
                {page.translation_items.map((item, index) => (
                  <Fade key={index} delay={250 * (index + 1)} duration={2250}>
                    <li className="md:flex border-t border-white-opaque-50 py-4 md:px-4 -mb-1 md:mb-0 md:leading-none">
                      <div className="md:w-1/2 pb-1 md:pb-0">
                        <h5 className="uppercase tracking-widest">
                          {item.translation_heading}
                        </h5>
                      </div>
                      <div className="md:w-1/2 pb-1 md:pb-0">
                        <p>{item.translation_content}</p>
                      </div>
                    </li>
                  </Fade>
                ))}
              </ul>
            </Fade>
          </div>
        </div>
      </div>
      <Footer links={footerLinks} />
    </Layout>
  )
}

GlossaryPage.propTypes = {
  location: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
}

export const GlossaryQuery = graphql`
  query {
    prismicTeReo {
      data {
        meta_description
        meta_title
        heading
        subheading
        translation_items {
          translation_heading
          translation_content
        }
      }
    }
  }
`

export default GlossaryPage
